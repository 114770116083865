// In Next.js, this file would be called: app/providers.tsx
"use client";

import { ClerkProvider } from "@clerk/nextjs";
import { setUseWhatChange } from "@simbathesailor/use-what-changed";
import toast from "react-hot-toast";
// // Since QueryClientProvider relies on useContext under the hood, we have to put 'use client' on top
// import {
// 	isServer,
// 	QueryCache,
// 	QueryClient,
// 	QueryClientProvider,
// } from "@tanstack/react-query";

// function makeQueryClient() {
// 	return new QueryClient({
// 		defaultOptions: {
// 			queries: {
// 				// With SSR, we usually want to set some default staleTime
// 				// above 0 to avoid refetching immediately on the client
// 				staleTime: 5 * 1000,
// 			},
// 		},
// 		queryCache: new QueryCache({
// 			onError: (error, query) => {
// 				// 🎉 only show error toasts if we already have data in the cache
// 				// which indicates a failed background update
// 				if (query.state.data !== undefined)
// 					toast.error(`Something went wrong: ${error.message}`);
// 			},
// 		}),
// 	});
// }

// let browserQueryClient: QueryClient | undefined = undefined;

// function getQueryClient() {
// 	if (isServer) {
// 		// Server: always make a new query client
// 		return makeQueryClient();
// 	} else {
// 		// Browser: make a new query client if we don't already have one
// 		// This is very important, so we don't re-make a new client if React
// 		// suspends during the initial render. This may not be needed if we
// 		// have a suspense boundary BELOW the creation of the query client
// 		if (!browserQueryClient) browserQueryClient = makeQueryClient();
// 		return browserQueryClient;
// 	}
// }

export default function Providers({
	children,
}: Readonly<{
	children: React.ReactNode;
}>) {
	setUseWhatChange(process.env.NODE_ENV === "development");
	// NOTE: Avoid useState when initializing the query client if you don't
	//       have a suspense boundary between this and the code that may
	//       suspend because React will throw away the client on the initial
	//       render if it suspends and there is no boundary
	// const queryClient = getQueryClient();

	return (
		<ClerkProvider>
			{/* <QueryClientProvider client={queryClient}> */}
			{children}
			{/* </QueryClientProvider> */}
		</ClerkProvider>
	);
}
